body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4faf7 ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container, .toolbar {
  max-width: 800px;
  margin-left: auto;
  margin-right:auto;
}

.toolbar {
  margin-bottom: 20px;
}

.indent td:first-of-type{
  padding-left: 40px;
}

.main-theme-text {
  color: white !important;
}

.link {
  cursor:pointer;
  color:#009639;
  margin-left: 10px;
}
.link:hover {
  text-decoration: underline;
}
.section-header {
  font-weight: bold !important;
  font-size: 19px !important;
}
.panel {
  border-left: solid  #009639!important;
}
.panel-highlight1 {
  background-color: #e5f4eb!important;

  
}
.panel-highlight1 .MuiTableRow-hover:hover {
  background-color: #f4faf7 !important;
}

.panel-highlight2 {
  border: solid  #009639  !important;
}
.sum-row {
  background-color: #f4faf7;
  font-weight: bold !important;
}

.sum-row-highlight td {
  background-color: #f4faf7 ;
  font-weight: bold;
  border-top-style: groove;
}

#drop-down-language {
  background-color: white;
  padding-top:7px !important;
  padding-bottom: 7px !important;
}


#mobile-info {
  display: none;
}

@media only screen and (max-width: 600px) { 
  #mobile-info {
    display: block;
  }

  .section-seed-cost .MuiAccordionSummary-content {
    flex-direction: column;
  }
  .link {
    margin-left: 0;
  }

}

.MuiTableRow-hover:hover {
  background-color: #e5f4eb!important ;
}

/*Pudotusvalikko (ei kielivalinta) */
.MuiSelect-select:not(#drop-down-language) {
  padding-top:10px !important;
  padding-bottom: 10px !important;
}

/*Paneelin otsikko ja avausikoni */
.MuiAccordionSummary-content,
.MuiAccordionSummary-expandIcon,
.MuiAccordionSummary-root {
  margin: 1px !important;
  min-height: 20px !important;
}
/*pudotusvalikko ja syötekentät */
.MuiInputBase-root, .MuiDataGrid-root, i {
  font-size: 0.875rem !important;
}


/*Numberosyötekentät*/
.number-input {
  min-width: 100px !important;
  max-width: 120px !important;
}
.row-name {
  min-width: 215px;
}

.MuiAlert-root{
  margin-bottom: 100px ;
  color: black !important;
}
